/**
* Utility helper functions of making AJAX requests
* TOC
* 1. Post
*/

// checks if response contains error code
const handleException = function (response) {
  let exception = {
    isError: false,
    errorMessage: '',
  };

  if (response.status === 400) {
    exception = {
      isError: true,
      errorMessage: 'Oops. This endpoint returned a 400. Bad request.',
    };
  } else if (response.status === 401) {
    exception = {
      isError: true,
      errorMessage: 'User is unauthorized to view this content.',
    };
  } else if (response.status === 422) {
    exception = {
      isError: true,
      errorMessage: 'Oops. This endpoint returned a 422. Unprocessable entity.',
    };
  } else if (response.status === 404) {
    exception = {
      isError: true,
      errorMessage: 'Oops. This endpoint returned a 404. Please try again later.',
    };
  } else if (response.status === 500) {
    exception = {
      isError: true,
      errorMessage: 'Oops. This endpoint returned a 500. Please try again later.',
    };
  }

  if (exception.isError) {
    console.log('== Exception ==');
    console.log(response);
  }

  return exception;
};

class Ajax {
  static post(url, passedPayload, passedHeaders) {
    const headers = passedHeaders || {};
    headers['Content-Type'] = 'application/json';
    const payload = passedPayload || {};

    const request = new Request(url, {
      headers: new Headers(headers),
      // credentials: 'include',
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify(payload),
    });

    return fetch(request).then((response) => {
      const exception = handleException(response);
      if (exception.isError) {
        return {
          error: true,
          message: exception.errorMessage,
        };
      }
      return response.json();
    }).catch((error) => {
      console.log(error);
    });
  }

  static put(url, passedPayload, passedHeaders) {
    const headers = passedHeaders || {};
    headers['Content-Type'] = 'application/json';
    const payload = passedPayload || {};

    const request = new Request(url, {
      headers: new Headers(headers),
      // credentials: 'include',
      mode: 'cors',
      method: 'PUT',
      body: JSON.stringify(payload),
    });

    return fetch(request).then((response) => {
      const exception = handleException(response);
      if (exception.isError) {
        return {
          error: true,
          message: exception.errorMessage,
        };
      }
      return response.json();
    }).catch((error) => {
      console.log(error);
    });
  }

  static get(url, token, passedHeaders) {
    const headers = passedHeaders || {};
    headers['Content-Type'] = 'application/json';
    headers.Authorization = token ? 'Bearer ' + token : '';

    const request = new Request(url, {
      headers: new Headers(headers),
      // credentials: 'include',
      mode: 'cors',
      method: 'GET',
    });

    return fetch(request).then((response) => {
      const exception = handleException(response);
      if (exception.isError) {
        throw new Error(exception.errorMessage);
      } else {
        return response.json();
      }
    }).catch((error) => {
      console.log(error);
    });
  }
}

export default Ajax;
