import React, { Component } from 'react';

class FullWidthImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div
        className={process.env.BANNER_TYPE === 'slim' ? 'full-width-image -slim' : 'full-width-image'}
        style={{
          backgroundImage: 'url(' + process.env.BANNER_BACKGROUND + ')',
        }}>
        &nbsp;
      </div>
    );
  }
}

export default FullWidthImage;
