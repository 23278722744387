import '../../config/flow';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as fcl from '@onflow/fcl';

export default function FlowWallet(props) {
  const [user, setUser] = useState({ loggedIn: null });

  useEffect(() => {
    fcl.currentUser.subscribe(setUser);
  }, []);

  useEffect(() => {
    const { onInitAddress, onWalletConnection } = props;
    if (onInitAddress) {
      onInitAddress(user);
    }
    if (onWalletConnection) {
      onWalletConnection(user);
    }
  }, [user]);

  const Login = (e) => {
    const { onLogInAttempt } = props;
    e.preventDefault();
    fcl.logIn();
    onLogInAttempt();
  };

  const GetUser = () => {
    const { onWalletConnection } = props;
    onWalletConnection(user);
  };

  const AuthedState = () => {
    const { mode } = props;
    // GetUser();
    return (
      <div>
        {mode !== 'transfer'
        && (
        <div>
          <div className="account-subheading">
            Your wallet address:&nbsp;
            {user?.addr ?? 'No Address'}
          </div>
          <button type="button" className="button" onClick={fcl.unauthenticate}>Disconnect wallet</button>
        </div>
        )}
      </div>
    );
  };

  const UnauthenticatedState = () => (
    <div>
      <a href="#" className="button" onClick={(e) => Login(e)}>Connect a flow wallet</a>
      {/* <button type="button" onClick={fcl.signUp}>Sign Up</button> */}
    </div>
  );

  return (
    <div>
      {user.loggedIn
        ? <AuthedState />
        : <UnauthenticatedState />}
    </div>
  );
}

FlowWallet.propTypes = {
  onWalletConnection: PropTypes.func,
  onLogInAttempt: PropTypes.func,
  onInitAddress: PropTypes.func,
};
