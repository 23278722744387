import React, { Component } from 'react';

class FullPageLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="full-site-loader">
        <div className="full-site-loader__content">
          {process.env.LOADER_VIDEO
            ? (
              <video width="200" autoPlay="autoplay" playsInline loop muted>
                <source src={process.env.LOADER_VIDEO} type="video/mp4" />
                <track
                  default
                  kind="captions"
                  srcLang="en"
                  src="" />
                Your browser does not support the video tag.
              </video>
            )
            : <img src={process.env.LARGE_LOGO_INVERSE || process.env.LARGE_LOGO} alt="ethos" width={process.env.LARGE_LOGO_WIDTH} />}
          {/* <div className="full-site-loader__text">Loading...</div> */}
        </div>
      </div>
    );
  }
}

export default FullPageLoader;
