import moment from 'moment-mini';
import Ajax from './Ajax';

/**
* Utility helper for Account functions
*/

class Announcements {
  constructor() {
    this.apiUrl = process.env.SERVER_URL;
  }

  getAnnouncementsByBrand = (brandId, token) => {
    const that = this;
    return Ajax.get(that.apiUrl + 'posts/Brand?creatorId=' + brandId, token)
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }
}

export default new Announcements();
