import React, { Component } from 'react';

class SkullJewerlyFAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div
        id="homeFaq"
        className="about-section"
        style={{
          backgroundImage: 'url(' + process.env.FAQ_BACKGROUND + ')',
        }}>
        <div className="about-section__inner">
          
          <div className="about-section__title">
            FAQ
          </div>
          <div className="about-section__row">
            <div className="faq-question">
              What’s an NFT?
            </div>
            <div className="faq-answer">
              <p>{'NFT, short for "non-fungible token," is the digital badass that\'s shaking up the game. It\'s a digital asset that\'s one of a kind and can\'t be duplicated.'}</p>
              <p>{'Imagine having a baseball card signed by your favorite player. Sure, there may be other cards with their picture on them, but your card is the only one that\'s got their signature. An NFT works the same way - it\'s an exclusive digital item that\'s truly unique and can\'t be copied.'}</p>
              <p>{'So if you\'re looking to own something truly rare and valuable, an NFT from skulljewelry.com might be right up your alley.'}</p>
            </div>
            <div className="faq-question">
              How do I purchase this NFT?
            </div>
            <div className="faq-answer">
              <p>When the campaign goes live, simply click buy-now and purchase with your credit card. </p>
              <p>Through this process you will be prompted to create an account if you do not have one already.  This is to ensure you can view and transfer your NFT in the future. </p>
            </div>
            <div className="faq-question">
              {'Do I need cryptocurrency to buy a '}
              {' '}
              {process.env.SITE_NAME}
              {' '}
              {' NFT?'}
            </div>
            <div className="faq-answer">
              <p>Not at all. You can pay using your credit card.</p>
            </div>
            <div className="faq-question">
              What happens when I buy one of the digital assets?
            </div>
            <div className="faq-answer">
              <p>{'When you buy an NFT, we\'ll create a digital record of it on something called the blockchain. Think of the blockchain as a digital ledger that keeps track of all the digital assets in the crypto world. By "minting" your NFT, we\'re making sure that it\'s officially recorded and that you can prove you own it. It\'s kind of like getting a certificate of ownership for a rare item - you can show it off and prove that it\'s really yours.'}</p>
            </div>
            <div className="faq-question">
              What happens if I don’t have a Crypto Wallet?
            </div>
            <div className="faq-answer">
              <p>
                {'Not to worry, you do not need a crypto wallet to purchase this '}
                {' '}
                {process.env.SITE_NAME}
                {' '}
                {' NFT.'}
              </p>
              <p>We are here to make things easier, transferring your NFT can be done any time with a few simple clicks.</p>
            </div>
            <div className="faq-question">
              Is there support available if I need help?
            </div>
            <div className="faq-answer">
              <p>Yes, absolutely! We are here to help every step of the way.</p>
              <p>At any time, if you have any questions or need any support, please email us and we will be happy to help&nbsp;you:</p>
              <p><a href={'mailto:' + process.env.CLIENT_EMAIL}>{process.env.CLIENT_EMAIL}</a></p>
              <p><a href={process.env.CLIENT_WEBSITE} target="_blank" rel="noreferrer">{process.env.CLIENT_WEBSITE}</a></p>
            </div>
          </div>
          <div className="about-section__logo">
            <img src={process.env.LARGE_LOGO} width={process.env.LARGE_LOGO_WIDTH} alt="ethos" />
          </div>
        </div>
      </div>
    );
  }
}

export default SkullJewerlyFAQ;
