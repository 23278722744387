import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-mini';
import * as fcl from '@onflow/fcl';
import ElipsDropdown from '../common/ElipsDropdown';
import AccountManager from '../../managers/Account';
import { getBlockchainType } from '../../managers/Helpers';

const NftCard = ({
  item, onViewerSelect, onTransfer, onCancel, onGenerateNftPass,
}) => {
  const [transactionStatus, setTransactionStatus] = useState(0);
  const [utilityCollapse, setUtilityCollapse] = useState(false);
  const [detailsCollapse, setDetailsCollapse] = useState(false);

  const flowscanUrl = process.env.BLOCKCHAIN_ENVIRONMENT === 'test' ? 'https://testnet.flowview.app/account/' : 'https://www.flowview.app/account/';
  const collectionName = 'ethos' + item.asset.name.replace(/\s/g, '');
  const polyscanUrl = process.env.BLOCKCHAIN_ENVIRONMENT === 'test' ? 'https://mumbai.polygonscan.com/' : 'https://polygonscan.com/';

  const TransferNFT = (nft) => {
    onTransfer(nft);
  };

  const launchViewer = (e) => {
    e.preventDefault();
    onViewerSelect();
  };

  const GenerateNftPass = (nft) => {
    onGenerateNftPass(nft);
  };

  const CancelTransfer = (nft) => {
    const updatedNft = nft;
    // updatedNft.hasPendingTransfer = false;
    // make sure the user confirms the cancel
    onCancel(updatedNft);
  };
  
  const WithdrawNft = async (_COLLECTION_NAME_) => {
    // create collection
    try {
      const transactionId = await fcl.mutate({
        cadence: `

            import ${_COLLECTION_NAME_} from 0x${_COLLECTION_NAME_}
            import NonFungibleToken from 0x631e88ae7f1d7c20
            
            transaction {
            
              prepare(acct: AuthAccount) {
                if acct.borrow<& ${_COLLECTION_NAME_}.Collection>(from: ${_COLLECTION_NAME_}.CollectionStoragePath) == nil{

                    let collection <- Chapter2Bikes.createEmptyCollection()
            
                    // store the empty NFT Collection in account storage
                    acct.save<@NonFungibleToken.Collection>(<-collection, to: ${_COLLECTION_NAME_}.CollectionStoragePath)
            
                    log("Collection created.")
            
                    // create a public capability for the Collection
                    acct.link<&${_COLLECTION_NAME_}.Collection{NonFungibleToken.CollectionPublic, ${_COLLECTION_NAME_}.CollectionPublic}>(
                        ${_COLLECTION_NAME_}.CollectionPublicPath,
                        target: ${_COLLECTION_NAME_}.CollectionStoragePath
                    )
                    log("Capability created")
                }
              }
            
              execute {
              }
            }
            `,
        args: () => [],
        payer: fcl.authz,
        proposer: fcl.authz,
        authorizations: [fcl.authz],
        limit: 9999,
      });
      
      fcl.tx(transactionId).subscribe((res) => {
        setTransactionStatus(res.status);
      });

      console.log(fcl.tx(transactionId).onceSealed());
    } catch (error) {
      // colllection already created
      console.log(error);
      throw error;
    } finally {
      // withdraw nft
      await AccountManager.claimNft(item.id);
    }
  };

  const toggleCollapse = (content) => {
    switch (content) {
      case 'utility': setUtilityCollapse(!utilityCollapse); break;
      case 'details': setDetailsCollapse(!detailsCollapse); break;
      default: break;
    }
    return null;
  };

  const formatThumbnail = (number, imageUrl, nft) => {
    if (nft && nft.drop) {
      if (nft.drop.dropId === '4208e12d-abc1-4180-af0f-d2083429784d') {
        const imagePath = 'https://ipfs.ethosnft.com/lovejules/2010-nfth-50-';
        let constructedPath = '';
        if (number < 10) {
          constructedPath = imagePath + '0' + number + '.png';
        } else {
          constructedPath = imagePath + number + '.png';
        }
        return constructedPath;
      }
    }
    return imageUrl;
  };
    
  const formatSerialNumber = (number, nft) => {
    if (nft.drop.dropId === '4208e12d-abc1-4180-af0f-d2083429784d') {
      const serialPrefix = '2010.NFTH.50.';
      let serialNumber = '';
      if (number < 10) {
        serialNumber = serialPrefix + '0' + number;
      } else {
        serialNumber = serialPrefix + number;
      }
      return serialNumber;
    }
    return number;
  };

  return (
    <div className={item.hasPendingTransfer ? 'card w-96 glass shadow-xl -transfer-mode' : 'card w-96 glass shadow-xl'} key={item.nftId}>
      <div className="card-header">
        <div className="card-headings">
          {/* <h3
            className="card-title"
            dangerouslySetInnerHTML={{ __html: item.asset.name + ': #' + formatSerialNumber(item.number) }}>
          </h3> */}
          <h3
            className="card-title"
            dangerouslySetInnerHTML={{ __html: item.assetVariation && item.assetVariation.variationName ? item.assetVariation.variationName : item.asset.name }}>
          </h3>
          {/* <h6 className="card-subtitle">{item.drop.name}</h6> */}
        </div>
        
        {/* {getBlockchainType(item.asset.blockchain) === 'Polygon'
          ? (
            <ElipsDropdown
          nft={item}
          chainLink={polyscanUrl + '/token/' + item.contractAddress + '?a=' + item.number}
          onTransferButton={() => TransferNFT(item)}
          onGenerateApplePass={() => GenerateNftPass(item)} />
          )
          : (
            <ElipsDropdown
          nft={item}
          chainLink={flowscanUrl + item.withdrawAddress + '/collection/' + collectionName + 'Collection/' + item.number}
          onTransferButton={() => TransferNFT(item)}
          onGenerateApplePass={() => GenerateNftPass(item)} />
          )} */}
                 
      </div>
      {item.imageUrl.includes('.mp4') && item.asset.mediaMP4
        ? (
      // <div className="collection-preview">
      //   <a href={this.formatThumbnail(item.number, item.asset.mediaMP4)} target="_blank" rel="noreferrer"><img src={this.formatThumbnail(item.number, item.asset.mediaThumbnailUrl)} alt="NFT Preview" /></a>
      // </div>
          <figure>
            <Link
              to={'/account/collection/' + item.nftId}>
              <video autoPlay="autoplay" loop muted>
                <source src={item.imageUrl || item.asset.mediaMP4} type="video/mp4" />
                <track
                  default
                  kind="captions"
                  srcLang="en"
                  src="" />
                Your browser does not support the video tag.
              </video>
            </Link>
          </figure>
        )
        : (
          <div className="collection-preview">
            {item.asset.mediaUrl && item.asset.mediaUrl.includes('.pdf')
              ? (
                <Link
                  to={'/account/collection/' + item.nftId}>
                  <img src={formatThumbnail(item.number, item.asset.mediaLocalIPFS || item.asset.mediaUrl, item)} alt="NFT Preview" />
                </Link>
              )
              : (
                <Link
                  to={'/account/collection/' + item.nftId}>
                  <img src={formatThumbnail(item.number, item.imageUrl || item.asset.mediaThumbnailUrl || item.asset.mediaLocalIPFS, item)} alt="NFT Preview" />
                </Link>
              )}
          </div>
        )}
      <div className="card-body">
        <button className={'collapsible' + (utilityCollapse ? ' active' : '')} type="button" onClick={() => toggleCollapse('utility')}>Description</button>
        <div className={'collapsible-content' + (utilityCollapse ? ' open' : '')}>
          <div className="collection-details__item" dangerouslySetInnerHTML={{ __html: item.asset.assetDescription }}>
          </div>
          {item.utility && item.utility.length > 0
          && (
          <div className="collection-details__additional">
            $25 Coupon Code:
            {' '}
            {item.utility[0].utilityValue}
          </div>
          )}
        </div>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                  
        <button className={'collapsible' + (detailsCollapse ? ' active' : '')} type="button" onClick={() => toggleCollapse('details')}>Details</button>
        <div className={'collapsible-content' + (detailsCollapse ? ' open' : '')}>
          <div className="collection-details__item">
            Blockchain:&nbsp;
            <strong>{getBlockchainType(item.asset.blockchain)}</strong>
          </div>
          <div className="collection-details__item">
            Minting Status:&nbsp;
            {!item.contractAddress
              ? (
                <strong className="collection-details__status -pending">
                  Pending
                </strong>
              )
              : (
                <strong className="collection-details__status -pending">
                  Minted
                </strong>
              )}
          </div>
          {!item.contractAddress
            ? (
              <div className="collection-details__item">
                ID:&nbsp;
                <strong>
                  {item.nftId}
                </strong>
              </div>
            )
            : (
              <div>
                {item.withdrawAddress
                  ? (
                    <div>
                      <div className="collection-details__item">
                        Wallet Address:&nbsp;
                        <a className="collection-details__status -pending" href={getBlockchainType(item.asset.blockchain) === 'Polygon' ? polyscanUrl + '/token/' + item.contractAddress + '?a=' + item.number : flowscanUrl + item.withdrawAddress + '/collection'} target="_blank" rel="noreferrer">
                          {' '}
                          {item.withdrawAddress}
                        </a>
                      </div>
                      <div className="collection-details__item">
                        Contract Address:&nbsp;
                        <a className="collection-details__status -pending" href={getBlockchainType(item.asset.blockchain) === 'Polygon' ? polyscanUrl + '/address/' + item.contractAddress : flowscanUrl + item.withdrawAddress + '/collection'} target="_blank" rel="noreferrer">
                          {item.contractAddress}
                        </a>
                      </div>
                      <div className="collection-details__item">
                        Token ID:&nbsp;
                        {item.number || item.tokenId}
                      </div>
                    </div>
                  )
                  : (
                    <div className="collection-details__item">
                      Contract Address:&nbsp;
                      <a className="collection-details__status -pending" href={getBlockchainType(item.asset.blockchain) === 'Polygon' ? polyscanUrl + '/token/' + item.contractAddress + '?a=' + item.number : flowscanUrl + item.contractAddress + '/collection'} target="_blank" rel="noreferrer">
                        {item.contractAddress}
                      </a>
                    </div>
                  )}
              </div>
            )}
          {item.drop.salesType === 1
            ? (
              <div className="collection-details__item">
                Date issued:&nbsp;
                <strong>
                  {moment(item.datePurchased).format('MMMM DD, YYYY')}
                </strong>
              </div>
            )
            : (
              <div className="collection-details__item">
                Date purchased:&nbsp;
                <strong>
                  {moment(item.datePurchased).format('MMMM DD, YYYY')}
                </strong>
              </div>
            )}

          {item.drop.salesType !== 1
            && (
            <div className="collection-details__item">
              Amount paid:&nbsp;
              <strong>
                $
                {item.txn.totalAmount}
              </strong>
            </div>
            )}
        </div>
        <div>
          {item.hasPendingTransfer
            ? (
              <div className="card-buttons" style={{ marginTop: '1rem' }}>
                <button className="btn -block" type="button" onClick={() => CancelTransfer(item)}>Cancel Transfer</button>
              </div>
            )

            : (
              <div>
                {/* {!item.withdrawAddress
                  ? (
                    <div className="card-buttons" style={{ marginTop: '1rem' }}>
                      {item.drop.dropId !== '049a891c-e124-4ed1-bba1-86d8f58124ac'
                      && <button className="btn -block" type="button" onClick={() => TransferNFT(item)}>Transfer</button>}
                    </div>
                  )
                  : (
                    <div className="card-buttons" style={{ marginTop: '1rem' }}>
                      <a className="btn -block" href={flowscanUrl + item.withdrawAddress + '/transfers'} target="_blank" rel="noreferrer">View on blockchain</a>
                    </div>
                  )}
                <button className="button-apple-wallet -block" type="button" onClick={() => GenerateNftPass(item)}>
                  <img src="http://ipfs.ethosnft.com/ethos/button-apple-wallet.png" alt="Add to Apple Wallet" />
                </button> */}
                <div className="card-buttons" style={{ marginTop: '1rem' }}>
                  <Link
                    to={'/account/collection/' + item.nftId}
                    className="btn -block">
                    View Details
                  </Link>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
export default NftCard;
