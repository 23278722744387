import React, { Component } from 'react';
import FlowWallet from './flowWallet';
import Account from '../../managers/Account';
import MetamaskWallet from '../wallet/metamaskWallet';

class AccountWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggingIn: false,
    };
  }

  updateFlowWallet = async (user) => {
    const { isLoggingIn } = this.state;
    const payload = {
      blockchain: 'flow',
      walletType: 'blocto',
      walletAddress: null,
    };
    if (isLoggingIn) {
      payload.walletAddress = user.addr;
      await Account.addWallet(payload, Account.getToken());
      // TODO: Save the wallet address to our database
    }
  }

  updateEthereumWallet = async (user) => {
    const { isLoggingIn } = this.state;
    const payload = {
      blockchain: 'polygon',
      walletType: 'metamask',
      walletAddress: user.addr,
    };
    await Account.addWallet(payload, Account.getToken());
  }

  onLogInAttempt = () => {
    this.setState({
      isLoggingIn: true,
    });
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <div className="account-box -account-section">
          <h2 className="account-heading__h2">
            Wallet Settings
          </h2>
          <div className="account-subheading">
            <p>
              We recommend that you connect your own wallet. Once your wallet is connected you can transfer / claim any NFT that ethos may be holding for you so that you have full and complete ownership of your digital assets. Some of the benefits of having full ownership over your digital assets are highlighted&nbsp;
              
              <a href="https://www.ethosnft.com/blog" target="_blank" rel="noreferrer">here</a>
              .
            </p>
            <p>
              {'If you don\'t want to connect your own wallet right now, that\'s ok! We are happy to hold any digital assets for you to make the process easier and we will always enable you to connect your own wallet in the future.'}
            </p>
          </div>
          <div className="wallet-component">
            <MetamaskWallet
              onConnect={(user) => this.updateEthereumWallet(user)} />
          </div>
          {/* <div>
            <FlowWallet
              onWalletConnection={(user) => this.updateFlowWallet(user)}
              onLogInAttempt={() => this.onLogInAttempt()} />
          </div> */}
        </div>
      </div>
    );
  }
}

export default AccountWallet;
