/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import moment from 'moment-mini';
import { getBlockchainType, truncateString, getScanUrl } from '../../managers/Helpers';

function UtilityList({ asset }) {
  return (
    <div className="asset-information">
      <h2 className="digital-asset__subheader">Technical information</h2>
      <div className="asset-information__data">
        <div className="asset-information__data--col">
          Blockchain
          <span>{getBlockchainType(asset.asset.blockchain)}</span>
        </div>
        <div className="asset-information__data--col">
          Status
          {!asset.contractAddress
            ? (
              <span>
                Pending
              </span>
            )
            : (
              <span>
                Minted
              </span>
            )}
        </div>
        <div className="asset-information__data--col">
          Unique ID
          <span>{asset.tokenId || asset.number || '1'}</span>
        </div>
        <div className="asset-information__data--col">
          Contract Address
          <a href={getScanUrl(asset)} target="_blank" rel="noreferrer">{asset.contractAddress ? truncateString(asset.contractAddress, 30) : '0x0000000000'}</a>
        </div>
        <div className="asset-information__data--col">
          Date Created
          <span>{moment(asset.txn.dateStamp).format('MMMM DD, YYYY')}</span>
        </div>
        <div className="asset-information__data--col">
          Amount Paid
          <span>
            $
            {asset.txn.totalAmount}
          </span>
        </div>
      </div>
    </div>
  );
}

export default UtilityList;
