import React, { useState } from 'react';
import { AzureMP } from 'react-azure-mp';

const AzureMediaPlayer = ({ src }) => {
  const [showModal, setShowModal] = useState(true);
  return (
    <div>
      {src
      && (
      <AzureMP
        options={{ autoplay: true }}
        src={[{ src, type: 'video/mp4' }]} />
      )}
    </div>
  );
};
export default AzureMediaPlayer;
