import React, { Component } from 'react';
import { toast } from 'react-toastify';
import Throbber from '../throbber';
import AccountManager from '../../managers/Account';

const passwordRequirements = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

class AccountPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordCurrent: '',
      passwordNew: '',
      passwordConfirm: '',
      isLoading: false,
    };
  }

  componentDidMount() {}

  onEnterKey = (e) => {
    if (e.keyCode === 13) {
      // this.validateForm();
    }
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  changePassword = async (e) => {
    const { passwordCurrent, passwordNew } = this.state;
    e.preventDefault();
    if (this.validateForm()) {
      const payload = {
        currentPassword: passwordCurrent,
        newPassword: passwordNew,
      };
      this.setState({
        isLoading: true,
      });
      const newPassword = await AccountManager.changePassword(payload, AccountManager.getToken());
      this.setState({
        isLoading: false,
      });
      if (newPassword && !newPassword.error) {
        this.setState({
          passwordCurrent: '',
          passwordNew: '',
          passwordConfirm: '',
        });
        toast.success('Password changed! Please use your new password the next time you log in!');
      } else {
        toast.error(newPassword && newPassword.message ? newPassword.message : 'Oops! Something went wrong. Please try again later.');
      }
    }
  }

  validateForm = () => {
    const { passwordCurrent, passwordNew, passwordConfirm } = this.state;
    if (passwordCurrent === ''
        || passwordNew === ''
        || passwordConfirm === '') {
      toast.error('Please fill out all fields.');
      return false;
    }
    if (passwordNew !== passwordConfirm) {
      toast.error('Passwords don\'t match.');
      return false;
    }
    if (!passwordRequirements.test(passwordNew)) {
      toast.error('Passwords must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters');
      return false;
    }
    return true;
  }

  render() {
    const {
      isLoading, passwordCurrent, passwordNew, passwordConfirm,
    } = this.state;
    return (
      <div>
        <div className="account-box -account-section">
          {isLoading
            && <Throbber throbberText="Updating your password! Hang tight..." />}
          
          <h2 className="account-heading__h2">
            Account Password
          </h2>
          <div className="account-subheading">
            Change your password here
          </div>
          <div className="form-container">
            <div className="form-section">
              <label className="label-block" htmlFor="passwordCurrent">Current Password</label>
              <input
                className="input-block"
                id="passwordCurrent"
                type="password"
                value={passwordCurrent}
                onChange={(e) => this.handleInputChange(e)}
                onKeyUp={(e) => this.onEnterKey(e)} />
            </div>
            <div className="form-section">
              <label className="label-block" htmlFor="passwordCurrent">New Password</label>
              <input
                className="input-block"
                id="passwordNew"
                type="password"
                value={passwordNew}
                onChange={(e) => this.handleInputChange(e)}
                onKeyUp={(e) => this.onEnterKey(e)} />
              <div className="form-requirements">(Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters)</div>
            </div>
            <div className="form-section">
              <label className="label-block" htmlFor="passwordConfirm">Confirm New Password</label>
              <input
                className="input-block"
                id="passwordConfirm"
                type="password"
                value={passwordConfirm}
                onChange={(e) => this.handleInputChange(e)}
                onKeyUp={(e) => this.onEnterKey(e)} />
            </div>
            <div className="form-cta">
              <button
                  className="button"
                  type="submit"
                  onClick={(e) => this.changePassword(e)}>
                Change Password
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountPassword;
