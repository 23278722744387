import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import CookieConsent from 'react-cookie-consent';
import HomeHero from '../components/hero/homeHero';
import HomeCountdown from '../components/countdown/homeCountdown';
import FAQ from '../components/about/faq';
import CreatorManager from '../managers/Creator';
import FullPageLoader from '../components/throbber/loader';
import Footer from '../components/navigation/footer';
import FullWidthImage from '../components/hero/fullWidthImage';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropId: process.env.DROP_ID,
      dropData: null,
      isLoading: true,
    };
  }

  getDropData = async () => {
    const { dropId } = this.state;
    const dropData = await CreatorManager.getDrop(dropId);

    if (dropData) {
      this.setState({
        isLoading: false,
        dropData,
      });
    }
  }

  async componentDidMount() {
    await this.getDropData();
  }

  scrollToSection = (element) => {
    const scrollToElement = document.getElementById(element);
    scrollToElement.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const { dropData, isLoading } = this.state;
    return (
      <div>
        {isLoading
          ? (
            <div>
              <FullPageLoader />
            </div>
          )
          : (
            <div>
              {dropData
                && (
                <div>
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    pauseOnHover />
                  <HomeHero
                    data={dropData}
                    type="countdown"
                    onLearnMore={() => this.scrollToSection('moreInfoElement')}
                    onFaqButton={() => this.scrollToSection('homeFaq')} />
                  <HomeCountdown
                    layoutStyle="default"
                    assetData={dropData.asset}
                    dropData={dropData.drop}
                    onFaqButton={() => this.scrollToSection('homeFaq')} />
                  {dropData.asset2
                    && (
                    <HomeCountdown
                      layoutStyle="offset"
                      assetData={dropData.asset2}
                      dropData={dropData.drop2}
                      onFaqButton={() => this.scrollToSection('homeFaq')} />
                    )}
                  <FullWidthImage />
                  <FAQ />
                  <Footer />
                  <CookieConsent
                    cookieName={process.env.CREATOR_SUBDOMAIN + 'CookieConsent'}>
                    <a href="https://www.ethosnft.com">ethos</a>
                    {' '}
                    uses cookies to provide necessary website functionality,
                    improve your experience and analyze our traffic. By using our website, you agree to&nbsp;
                    <a target="_blank" href="https://www.ethosnft.com/privacy" rel="noreferrer">our privacy policy</a>
                    {' '}
                    and our cookies usage.
                    
                  </CookieConsent>
                </div>
                )}
            </div>
          )}
      </div>
    );
  }
}

export default Home;
