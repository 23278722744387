import React, { useState, useEffect } from 'react';
import CollectionHero from '../collection/collectionHero';
import DigitalAssets from '../collection/digitalAssets';
import AccountManager from '../../managers/Account';
import UtilityManager from '../../managers/Utility';
import CollectionUtility from '../collection/collectionUtility';

function AccountCollection() {
  // State variables using useState
  const [brandId, setBrandId] = useState(null);
  const [allUtility, setAllUtility] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filteredUtility, setFilteredUtility] = useState([]);
  const [tab, setTab] = useState(0);
  const [filterLabel, setFilterLabel] = useState('');
  const getAllBrandUtility = async () => {
    // TODO: on component mount, attempt to get all the utililty for a brand
    const allUtility = await UtilityManager.getAllUtility(process.env.CREATOR_ID, AccountManager.getToken());
    if (allUtility && allUtility.utility) {
      setAllUtility(allUtility.utility.collectionUtility.concat(allUtility.utility.nftUtility));
    }
  };

  const setUtilityFilter = async (filterType, filterLabel) => {
    setTab(filterType);
    setFilterLabel(filterLabel);
    const filterResults = UtilityManager.sortUtility(process.env.CREATOR_ID, filterType, AccountManager.getToken());
    setFilteredUtility(filterResults);
  };

  const updateFilterOptions = () => {
    const parsedFilterOptions = [];
    if (allUtility.filter((e) => e.utilityType === 1 || e.utilityType === 3).length > 0) {
      parsedFilterOptions.push({
        id: 1,
        label: 'Merch',
      });
    }
    if (allUtility.filter((e) => e.utilityType === 2).length > 0) {
      parsedFilterOptions.push({
        id: 2,
        label: 'Media',
      });
    }
    if (allUtility.filter((e) => e.utilityType === 4).length > 0) {
      parsedFilterOptions.push({
        id: 3,
        label: 'Offers',
      });
    }
    setFilterOptions(parsedFilterOptions);
  };

  useEffect(() => {
    setBrandId(process.env.CREATOR_ID);
    getAllBrandUtility(process.env.CREATOR_ID);
    if (!AccountManager.isLoggedIn()) {
      window.location = '/login';
    }
  }, []);

  useEffect(() => {
    updateFilterOptions();
  }, [allUtility]);

  return (
    <div className="account-collection">
      {brandId
      && <CollectionHero brandId={brandId} filterOptions={filterOptions} onFilterSelect={(filterType, filterLabel) => setUtilityFilter(filterType, filterLabel)} />}
      <div className="account-collection__body">
        {tab === 0
        && (
        <DigitalAssets
          brandId={brandId} />
        )}
        {filteredUtility && tab !== 0
          && <CollectionUtility utilityType={tab} utilityLabel={filterLabel} utilityList={filteredUtility} />}

      </div>
    </div>
  );
}

export default AccountCollection;
