import React, { Component } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Ajax from '../../managers/Ajax';
import Throbber from '../throbber';
import AccountManager from '../../managers/Account';
import 'react-toastify/dist/ReactToastify.css';

const emailPattern = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registerFirstname: '',
      registerLastname: '',
      registerPhone: '',
      registerEmail: '',
      isLoading: false,
      dropId: process.env.DROP_ID,
      hasSignedUp: false,
      agreeTerms: false,
      hasPreRegBonus: process.env.PRE_REGISTRATION_BONUS,
      returningUser: false,
      hasClaimed: false,
      isEmailOnly: process.env.PRE_REGISTRATION_EMAIL_ONLY,
    };
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  onEnterKey = (e) => {
    if (e.keyCode === 13) {
      this.validateForm(e);
    }
  }

  formatPayload = () => {
    // should probably add some error handling/validation here
    const {
      isEmailOnly, agreeTerms, registerFirstname, registerLastname, registerPhone, registerEmail, dropId,
    } = this.state;

    if (isEmailOnly !== 'true') {
      if (registerFirstname === ''
      || registerLastname === ''
      || registerPhone === ''
      || registerEmail === '') {
        toast.error('Please complete all fields.');
        return false;
      }
    }

    if (!emailPattern.test(registerEmail)) {
      toast.error('Please enter a valid email address.');
      return false;
    }
    if (!agreeTerms) {
      toast.error('Please accept the terms and conditions.');
      return false;
    }
    const payload = {
      firstName: registerFirstname,
      lastName: registerLastname,
      telephone: registerPhone,
      email: registerEmail,
      location: '',
      signUpUrl: window.location.href,
      dropId,
    };
    return payload;
  }

  checkForExistingUser = async (email) => {
    const existingUser = await AccountManager.emailSearch(AccountManager.getToken(), email);
    if (existingUser && existingUser.emailExists) {
      return true;
    }
    return false;
  }

  submitForm = async (e) => {
    e.preventDefault();
    const { dropId, hasPreRegBonus } = this.state;
    const { onRegisterSuccess } = this.props;
    const payload = this.formatPayload();
    let isReturningUser = false;
    if (payload) {
      this.setState({
        isLoading: true,
      });
      try {
        // const register = await Ajax.post('https://localhost:7180/api/usersignup', payload);
        const register = await Ajax.post(process.env.SERVER_URL + 'usersignup', payload);
        if (register) {
          // TODO: if the hasPreRegBonus flag is set, check if this user has already signed up
          if (hasPreRegBonus === 'true') {
            isReturningUser = await this.checkForExistingUser(payload.email);
            if (!isReturningUser) {
              // save payload to localStorage
              localStorage.setItem(process.env.CREATOR_SUBDOMAIN + 'PreRegInfo', JSON.stringify(payload));
            } else {
              localStorage.setItem(process.env.CREATOR_SUBDOMAIN + 'PreRegExistingInfo', JSON.stringify(payload));
            }
            this.setState({
              isLoading: false,
              hasSignedUp: true,
              returningUser: isReturningUser,
            });
          } else {
            this.setState({
              isLoading: false,
              hasSignedUp: true,
            });
          }

          if (onRegisterSuccess) {
            onRegisterSuccess();
          }

          gtag('event', process.env.CREATOR_SUBDOMAIN + '_pre_registration', { // eslint-disable-line
            event_category: 'account_registration',
            event_label: 'User pre-registered for a drop',
          });

          localStorage.setItem(process.env.CREATOR_SUBDOMAIN + 'Countdown', dropId);
        } else {
          toast.error('Oops! Something went wrong. Please try again later');
        }
      } catch (error) {
        toast.error(error.message || 'Oops! Something went wrong. Please try again later');
        this.setState({
          isLoading: false,
        });
      }
    }
  }

  handleCheckboxChange = (e) => {
    let isChecked = false;
    if (e.target.checked) {
      isChecked = true;
    } else {
      isChecked = false;
    }
    this.setState({
      agreeTerms: isChecked,
    });
  }

  optInLogIn = (e) => {
    const { onLoginRequest } = this.props;
    e.preventDefault();
    onLoginRequest();
  }

  componentDidMount() {
    const { dropId } = this.state;
    let existingUserEmail;
    if (localStorage.getItem(process.env.CREATOR_SUBDOMAIN + 'PreRegExistingInfo')) {
      existingUserEmail = JSON.parse(localStorage.getItem(process.env.CREATOR_SUBDOMAIN + 'PreRegExistingInfo')).email;
    }

    if (localStorage.getItem(process.env.CREATOR_SUBDOMAIN + 'PreRegTokenClaimed') || localStorage.getItem(process.env.CREATOR_SUBDOMAIN + 'FreeToken')) {
      this.setState({
        hasClaimed: true,
      });
    }

    if (localStorage.getItem(process.env.CREATOR_SUBDOMAIN + 'Countdown') && localStorage.getItem(process.env.CREATOR_SUBDOMAIN + 'Countdown') === dropId) {
      this.setState({
        hasSignedUp: true,
        registerEmail: existingUserEmail || '',
        returningUser: !!existingUserEmail,
      });
    }
  }

  render() {
    const {
      isEmailOnly, hasClaimed, returningUser, hasPreRegBonus, hasSignedUp, registerFirstname, registerLastname, registerPhone, registerEmail, isLoading,
    } = this.state;
    const { type, buttonType } = this.props;
    return (
      <div>
        {hasSignedUp
          ? (
            <div className="register-container">
              {hasPreRegBonus === 'true'
                ? (
                  <div className={buttonType === 'block' ? 'register-results -center' : 'register-results'}>
                    {hasClaimed
                      ? (
                        <div>
                          Thank you for Pre Registering and claiming your free NFT. We will notify you via email when the drop goes live!
                        </div>
                      )
                      : (
                        <div>
                          <div>
                            <img className="register-results__icon" src="https://ipfs.ethosnft.com/ethos/icon-success.svg" alt="" />
                            <div className="register-results__header">Success!</div>
                            {process.env.PRE_REGISTER_SUCCESS}
                            <br />
                            <br />
                            {hasPreRegBonus === 'true'
                            && (
                            <div>
                              {returningUser
                                ? (
                                  <div>
                                    {'It looks like the email address '}
                                    {registerEmail}
                                    {' is already registered with ethos. '}
                                    <a href="#" onClick={(e) => this.optInLogIn(e)}>Log in now</a>
                                    {' to claim your FREE Greatness Calling NFT.'}
                                  </div>
                                )
                                : (
                                  <div>
                                    {'To claim your FREE Greatness Calling NFT, '}
                                    <a href="/register">finish your registration now</a>
                                    .
                                  </div>
                                )}
                            </div>
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                )
                : (
                  <div>
                    <div className={buttonType === 'block' ? 'register-results -center' : 'register-results'}>
                      <img className="register-results__icon" src="https://ipfs.ethosnft.com/ethos/icon-success.svg" alt="" />
                      <div className="register-results__header">Success!</div>
                      <div>
                        {'Thanks for signing up. Keep your eyes on your inbox. '}
                        {'We\'ll notify you as soon as the digital assets are available for purchase.'}
                      </div>
                    </div>
                  </div>
                )}
            </div>
          )
          : (
            <div className="register-container">
              {isLoading
              && <Throbber theme="branded" throbberText="Signing you up! One second..." />}
              <h2 className={buttonType === 'block' ? 'register-header -center' : 'register-header'}>
                {process.env.PRE_REGISTER_HEADER}
              </h2>
              <div className="form-container">
                {isEmailOnly !== 'true'
                && (
                <div>
                  <div className={type ? 'form-section -inverse' : 'form-section'}>
                    <label className="label-block" htmlFor="registerFirstname">First name</label>
                    <input
                    className="input-block"
                    id="registerFirstname"
                    type="text"
                    value={registerFirstname}
                    onChange={(e) => this.handleInputChange(e)}
                    onKeyUp={(e) => this.onEnterKey(e)} />
                  </div>
                  <div className={type ? 'form-section -inverse' : 'form-section'}>
                    <label className="label-block" htmlFor="registerLastname">Last name</label>
                    <input
                    className="input-block"
                    id="registerLastname"
                    type="text"
                    value={registerLastname}
                    onChange={(e) => this.handleInputChange(e)}
                    onKeyUp={(e) => this.onEnterKey(e)} />
                  </div>
                  <div className={type ? 'form-section -inverse' : 'form-section'}>
                    <label className="label-block" htmlFor="registerPhone">Phone number</label>
                    <input
                    className="input-block"
                    id="registerPhone"
                    type="text"
                    value={registerPhone}
                    onChange={(e) => this.handleInputChange(e)}
                    onKeyUp={(e) => this.onEnterKey(e)} />
                  </div>
                </div>
                )}
                <div className={type ? 'form-section -inverse' : 'form-section'}>
                  <label className="label-block" htmlFor="registerEmail">Email</label>
                  <input
                  className="input-block"
                  id="registerEmail"
                  type="email"
                  value={registerEmail}
                  onChange={(e) => this.handleInputChange(e)}
                  onKeyUp={(e) => this.onEnterKey(e)} />
                </div>
                <div className="form-section">
                  <input
                    id="registerAcceptTerms"
                    type="checkbox"
                    onChange={(e) => this.handleCheckboxChange(e)} />
                  <label className="label-inline" htmlFor="registerAcceptTerms">
                    I agree to the
                    {' '}
                    <a href="https://www.ethosnft.com/terms" target="_blank" rel="noreferrer">Terms &amp; Conditions</a>
                    .
                  </label>
                </div>
                <div className="form-cta">
                  <button type="button" className={buttonType === 'block' ? 'button -block' : 'button'} onClick={(e) => this.submitForm(e)}>Submit</button>
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}

Register.propTypes = {
  type: PropTypes.string,
  buttonType: PropTypes.string,
  onLoginRequest: PropTypes.func,
  onRegisterSuccess: PropTypes.func,
};

export default Register;
