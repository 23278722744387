import React, { useState, useEffect } from 'react';

const HowItWorks = ({ asset }) => {
  // const [transferCode, setTransferCode] = useState(null);
  const instructions = [
    {
      id: 1,
      title: 'Join',
      description: 'Enter an email and your Prime Pass will be issued. You’ll become a member in seconds.',
    },
    {
      id: 2,
      title: 'Check your email',
      description: 'Your email will contain your login link to access the Prime Mates platform. Click through to explore your benefits.',
    },
    {
      id: 3,
      title: 'Access Rewards',
      description: 'Enjoy benefits like discounts, pre-release products, merch, invites to exclusive events, competitions and access to content and much more.',
    },
  ];

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="how-it-works__wrapper">
      <div id="startContent" className="how-it-works">
        <div className="how-it-works__title">
          How it works
        </div>
        <div className="how-it-works__list">
          {instructions.map((step, index) => (
            <div key={step.id} className="how-it-works__item">
              <div className="how-it-works__number">{index + 1}</div>
              <div className="how-it-works__step-title">{step.title}</div>
              <div className="how-it-works__step-description">{step.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default HowItWorks;
