import React, { Component } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Throbber from '../throbber';
import AccountManager from '../../managers/Account';
import CreatorManager from '../../managers/Creator';

class ConfirmFreebie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      loadingText: 'Calculating taxes. Stand by...',
      agreeTerms: false,
      purchasePrice: null,
      prices: {},
      lastFour: '####',
    };
  }

  componentDidMount() {}

  confirmPurchase = async () => {
    const { agreeTerms } = this.state;
    const { onPurchaseAttempt } = this.props;
    const payload = {
      gasFee: 0,
    };

    if (!agreeTerms) {
      toast.error('Please agree to the terms and conditions.');
    } else {
      this.setState({
        isLoading: true,
      });

      onPurchaseAttempt(payload);
    }
  }

  componentDidUpdate(prevProps) {
    const { purchaseResult } = this.props;
    if (prevProps.purchaseResult !== purchaseResult) {
      console.log(purchaseResult);
    }
  }

  handleCheckboxChange = (e) => {
    let isChecked = false;
    if (e.target.checked) {
      isChecked = true;
    } else {
      isChecked = false;
    }
    this.setState({
      agreeTerms: isChecked,
    });
  }

  render() {
    const {
      isLoading, loadingText,
    } = this.state;
    return (
      <div>
        {isLoading
            && <Throbber throbberText={loadingText} />}
        <div className="account-heading">
          Confirm Your Purchase
        </div>
        <div className="confirm-purchase__container">
          {/* <div className="account-subheading">
            Confirm the details below details.
          </div> */}
          <div className="confirm-purchase__row">
            <div className="confirm-purchase__item">
              Purchase Price
            </div>
            <div className="confirm-purchase__item">
              $0.00
            </div>
          </div>
          <div className="confirm-purchase__row">
            <div className="confirm-purchase__item">
              Gas Fee
            </div>
            <div className="confirm-purchase__item -underline">
              $0.00
            </div>
          </div>
          <div className="confirm-purchase__row">
            <div className="confirm-purchase__item">
              Sub Total
            </div>
            <div className="confirm-purchase__item">
              $0.00
            </div>
          </div>
          <div className="confirm-purchase__row">
            <div className="confirm-purchase__item">
              Estimated Sales Tax
            </div>
            <div className="confirm-purchase__item -underline">
              $0.00
            </div>
          </div>
          <div className="confirm-purchase__row">
            <div className="confirm-purchase__item">
              Total
            </div>
            <div className="confirm-purchase__item">
              $0.00
            </div>
          </div>

          <div className="confirm-purchase__legal">
            {/* <ul>
              <li>The NFT carries a limited licence referencing the artwork.</li>
              <li>Your purchase is conditional and will only become final upon a) our receipt in full of your payment b) validation of your identity which may require your submission of official documents c) completion of a hold period which may be as long as three months.</li>
              <li>All of your payments are non-refundable.</li>
            </ul> */}
            <div className="confirm-purchase__terms">
              <input
                id="registerAcceptTerms"
                type="checkbox"
                onChange={(e) => this.handleCheckboxChange(e)} />
              <label className="label-inline -inverse" htmlFor="registerAcceptTerms">
                I agree to the Terms &amp; Conditions
                {' '}
                <a href="https://www.ethosnft.com/terms" target="_blank" rel="noreferrer">Terms &amp; Conditions</a>
                .
              </label>
            </div>
          </div>
        </div>
        <div className="form-cta -centered">
          <button
            type="submit"
            onClick={() => this.confirmPurchase()}>
            Confirm
          </button>
        </div>
      </div>
    );
  }
}

ConfirmFreebie.propTypes = {
  onPurchaseAttempt: PropTypes.func,
  purchaseResult: PropTypes.string,
};

export default ConfirmFreebie;
