/* eslint-disable no-param-reassign */

import moment from 'moment-mini';
import Ajax from './Ajax';

/**
* Utility helper for Account functions
*/

class Utility {
  constructor() {
    this.apiUrl = process.env.SERVER_URL;
    this.allUtility = null;
    this.previewUtility = null;
  }

  sortUtility = (creatorId, filterType, token) => {
    // offers is 1 or 4
    if (filterType === 1) {
      return this.allUtility.filter((e) => e.utilityType === 1 || e.utilityType === 3);
    }
    if (filterType === 2) {
      return this.allUtility.filter((e) => e.utilityType === 2);
    }
    if (filterType === 3) {
      return this.allUtility.filter((e) => e.utilityType === 4);
    }
    return this.allUtility;
  }

  getUtilityThumbnail = (utility) => {
    if (utility.utilityType === 1) {
      return utility.voucher.voucherImageUrl;
    } if (utility.utilityType === 2) {
      return utility.gatedContent.thumbnail;
    } if (utility.utilityType === 3) {
      return utility.gatedCommerce.productThumbnail;
    } if (utility.utilityType === 4) {
      return utility.genericPromotion.thumbnailUrl;
    }
    return 'https://ipfs.ethosnft.com/diageo/utility/thumb-blue-recipe2.jpg';
  }

  categorizeAssetUtility = (allUtility) => {
    const sortedUtility = [];
    let utilityObj;

    allUtility.forEach((utility) => {
      if (utility.utilityDescription.includes('Event:')) {
        console.log('Event found');
        console.log(utility);
        // newCategorizedUtility.push(utility);
        utility.utilityType = 5;
      }
    });

    if (allUtility.filter((e) => e.utilityType === 1 || e.utilityType === 3).length > 0) {
      console.log('=== merch ===');
      console.log(allUtility.filter((e) => e.utilityType === 1 || e.utilityType === 3));
      utilityObj = {
        label: 'Merch',
        utility: allUtility.filter((e) => e.utilityType === 1 || e.utilityType === 3),
      };
      sortedUtility.push(utilityObj);
      utilityObj = {};
    }

    if (allUtility.filter((e) => e.utilityType === 2).length > 0) {
      utilityObj = {
        label: 'Media',
        utility: allUtility.filter((e) => e.utilityType === 2),
      };
      sortedUtility.push(utilityObj);
      utilityObj = {};
    }

    if (allUtility.filter((e) => e.utilityType === 4).length > 0) {
      utilityObj = {
        label: 'Offers',
        utility: allUtility.filter((e) => e.utilityType === 4),
      };
      sortedUtility.push(utilityObj);
      utilityObj = {};
    }

    if (allUtility.filter((e) => e.utilityType === 5).length > 0) {
      utilityObj = {
        label: 'Events',
        utility: allUtility.filter((e) => e.utilityType === 5),
      };
      sortedUtility.push(utilityObj);
      utilityObj = {};
    }

    return sortedUtility;
  }

  getFilteredUtility = (creatorId, filterType, token) => {
    const that = this;
    let filterName = 'gated-content';
    if (filterType === 1) {
      filterName = 'voucher';
    }
    if (filterType === 2) {
      filterName = 'gated-content';
    }
    if (filterType === 3) {
      filterName = 'gated-commerce';
    }
    if (filterType === 4) {
      filterName = 'generic';
    }
    return Ajax.get(that.apiUrl + 'utility/creator/' + creatorId, token)
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  getAllUtility = (creatorId, token) => {
    const that = this;
    return Ajax.get(that.apiUrl + 'utility/creator/' + creatorId, token)
      .then((data) => {
        if (!data.utility || data.utility.length !== 0) {
          that.allUtility = data.utility.collectionUtility.concat(data.utility.nftUtility);
          return data;
        }
        return null;
      })
      .catch((error) => {
        throw error;
      });
  }

  getPreviewUtility = (assetId) => {
    const that = this;
    const utilityArray = [];
    return Ajax.get(that.apiUrl + 'utility/summary/' + assetId)
      .then((data) => {
        if (data.utility && data.utility.length > 0) {
          data.utility.forEach((utility) => {
            if (!utility.description.includes('Zebu Live Bar')) {
              utilityArray.push(utility);
            }
          });
          return utilityArray;
        }
        return null;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default new Utility();
