/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

function AssetFAQ({ creatorId, noHeader }) {
  const [questions, setQuestions] = useState([]);
  const toggleAnswer = (id, e) => {
    e.preventDefault();
    const cloneQuestions = [...questions];
    cloneQuestions.forEach((faq) => {
      if (faq.id === id) {
        faq.isOpen = !faq.isOpen;
      }
    });
    setQuestions(cloneQuestions);
    console.log('toggle answer');
    console.log(e);
    console.log(id);
  };

  const faq = [
    {
      id: 1,
      title: 'How do I log in?',
      answer: 'Simply navigate back to this link or find the original Prime Mates email in your inbox to log into the platform and view your Prime Pass.',
    },
    {
      id: 2,
      title: 'Can I add this to my Apple Wallet?',
      answer: 'Yes, once you’re in the platform you can add your Prime Pass to your apple wallet and use it to unlock Prime Time benefits in real life.',
    },
    {
      id: 3,
      title: 'How will I get notified when benefits are available?',
      answer: 'We\'ll make sure to keep you in the loop and notify you as soon as new benefits become available through email or SMS. You will also receive notification within the platform in real time.',
    },
    {
      id: 4,
      title: 'Can I invite others to join?',
      answer: 'If they love Prime Time, sure! We want to grow and engage with our community more than any other brand.',
    },
    {
      id: 5,
      title: 'Need help?',
      answer: 'Navigate to the contacts us section on our <a href="https://www.primetimelager.com/" target="_blank">website</a> (you’ll find it in the navigation header!). Drop us a note and we’ll get back to you.',
    },
    {
      id: 6,
      title: 'I’ve heard that this is a blockchain based loyalty scheme, what does that mean?',
      answer: 'We chose to host your Prime Passes on the Blockchain. This means that your unique Prime Pass is securely encrypted, but also means nobody can take it from you, not even Prime Time! Once you register, your pass is issued and it lives on the blockchain in your name. In the future, you will be able to use your Prime Pass in many other ways as new technology evolves. The fact that your Prime Passes are stored on the blockchain even means that you can sell it. If you want more information on this, reach out! We’ll keep you updated as our technology evolves.',
    },
  ];

  useEffect(() => {
    setQuestions(faq);
  }, []);

  return (
    <div className="asset-faq">
      {!noHeader
      && <h2 className="digital-asset__subheader">Frequently Asked Questions</h2>}
      {questions.map((faq) => (
        <div
          key={faq.id}
          className="asset-faq__item">
          {faq.isOpen
            ? (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                -
              </a>
            )
            : (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                +
              </a>
            )}
          <div
            className="asset-faq__item--question"
            onClick={(e) => toggleAnswer(faq.id, e)}>
            {faq.title}
          </div>
          <div
            className={faq.isOpen ? 'asset-faq__item--answer -open' : 'asset-faq__item--answer'}
            dangerouslySetInnerHTML={{ __html: faq.answer }}>
          </div>
        </div>
      ))}
      
    </div>
  );
}

export default AssetFAQ;
