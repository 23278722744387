import React, { Component } from 'react';

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div
        id="homeFaq"
        className="about-section"
        style={{
          backgroundImage: 'url(' + process.env.FAQ_BACKGROUND + ')',
        }}>
        <div className="about-section__inner">
          
          <div className="about-section__title">
            FAQ
          </div>
          <div className="about-section__row">
            <div className="faq-question">
              What’s an NFT?
            </div>
            <div className="faq-answer">
              <p>
                {'An NFT is a ‘non-fungible token’, which is a one-of-a-kind ownable digital asset that you can purchase. When you purchase one of these tokens, you are the official owner of an original '}
                {' '}
                {process.env.SITE_NAME}
                {' '}
                {' NFT that provides you some serious real-world benefits.'}
                {' '}
              </p>
            </div>
            <div className="faq-question">
              How do I purchase this NFT?
            </div>
            <div className="faq-answer">
              <p>When the campaign goes live, simply click buy-now and purchase with your credit card. </p>
              <p>Through this process you will be prompted to create an account if you do not have one already.  This is to ensure you can view and transfer your NFT in the future. </p>
            </div>
            <div className="faq-question">
              {'Do I need cryptocurrency to buy a '}
              {' '}
              {process.env.SITE_NAME}
              {' '}
              {' NFT?'}
            </div>
            <div className="faq-answer">
              <p>Not at all. You can pay using your credit card.</p>
            </div>
            <div className="faq-question">
              What happens when I buy one of the digital assets?
            </div>
            <div className="faq-answer">
              <p>We will be minting the digital assets, which is a crypto term meaning that your digital asset will become a part of the blockchain – the crypto world where digital assets are recorded.</p>
              {/* <p>If you’re one of the lucky ones who received an unlockable, you’ll also be gifted the real-world prizes linked to certain NFT purchases.</p> */}
            </div>
            <div className="faq-question">
              What happens if I don’t have a Crypto Wallet?
            </div>
            <div className="faq-answer">
              <p>
                {'Not to worry, you do not need a crypto wallet to purchase this '}
                {' '}
                {process.env.SITE_NAME}
                {' '}
                {' NFT.'}
              </p>
              <p>We are here to make things easier, transferring your NFT can be done any time with a few simple clicks.</p>
            </div>
            <div className="faq-question">
              Is there support available if I need help?
            </div>
            <div className="faq-answer">
              <p>Yes, absolutely! We are here to help every step of the way.</p>
              <p>At any time, if you have any questions or need any support, please email us and we will be happy to help&nbsp;you:</p>
              <p><a href={'mailto:' + process.env.CLIENT_EMAIL}>{process.env.CLIENT_EMAIL}</a></p>
              <p><a href={process.env.CLIENT_WEBSITE} target="_blank" rel="noreferrer">{process.env.CLIENT_WEBSITE}</a></p>
            </div>
          </div>
          <div className="about-section__logo">
            <img src={process.env.LARGE_LOGO} width={process.env.LARGE_LOGO_WIDTH} alt="ethos" />
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;
