/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import UtilityList from './utilityList';

function CollectionUtility({ utilityType, utilityLabel, utilityList }) {
  // const [questions, setQuestions] = useState([]);

  return (
    <div>
      <div className="digital-assets__header">
        {utilityLabel}
      </div>
      <div>
        <UtilityList
            layout="flex"
            noHeader
            assetUtility={[]}
            collectionUtility={utilityList} />
      </div>
    </div>
  );
}

export default CollectionUtility;
