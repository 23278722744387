import { config } from '@onflow/fcl';

// config({
//   'accessNode.api': process.env.BLOCKCHAIN_ENVIRONMENT === 'test' ? 'https://rest-testnet.onflow.org' : 'https://rest-mainnet.onflow.org',
//   'discovery.wallet': process.env.BLOCKCHAIN_ENVIRONMENT === 'test' ? 'https://fcl-discovery.onflow.org/testnet/authn' : 'https://fcl-discovery.onflow.org/authn',

// });
if (process.env.BLOCKCHAIN_ENVIRONMENT === 'test') {
  config(
    {
      'flow.network': 'testnet',
      'accessNode.api': 'https://rest-testnet.onflow.org',
      'discovery.wallet': 'https://fcl-discovery.onflow.org/testnet/authn',
      'app.detail.title': process.env.SITE_NAME,
      'app.detail.icon':
        'https://ethos.mypinata.cloud/ipfs/QmeHB7nq73T3X41s4FFuYj2boxPEWybfdPx9bcbVLLWFst/EthosLogo.jpeg',
      '0xEthosLilico': '0xeaf1bb3f70a73336',
      '0xEthosBlocto': '0xd8144e7c81e68eb9',
      '0xEthosDeployer': '0x5c9d115a7a44b304',
      '0xFlowStandards': '0x631e88ae7f1d7c20', // NonFungibleToken and Metadata interface
      '0xFlowToken': '0x7e60df042a9c0868',
      '0xFlowFungibleToken': '0x9a0766d93b6608b7',
    },
  );
} else {
  config(
    {
      'flow.network': 'mainnet',
      'accessNode.api': 'https://rest-mainnet.onflow.org',
      'discovery.wallet': 'https://fcl-discovery.onflow.org/authn',
      'app.detail.title': process.env.SITE_NAME,
      'app.detail.icon':
        'https://ethos.mypinata.cloud/ipfs/QmeHB7nq73T3X41s4FFuYj2boxPEWybfdPx9bcbVLLWFst/EthosLogo.jpeg',
      '0xEthosAdmin': '0x6a8a6bd12143eaa5',
      '0xEthosDeployer': '0xf92e92030f2701df',
      '0xFlowStandards': '0x1d7e57aa55817448', // NonFungibleToken and Metadata interface
      '0xFlowToken': '0x1654653399040a61',
      '0xFlowFungibleToken': '0xf233dcee88fe0abe',
    },
  );
}
