import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function RouterListener({ onUrlChange }) {
  const location = useLocation();
  useEffect(() => {
    if (typeof RISKX === 'undefined') {
      console.log('RISKX failed to initialize.');
    } else {
      console.log('=== set RISKX url ===');
      console.log(location.pathname);
      RISKX.go(location.pathname);
    }
    if (onUrlChange) {
      onUrlChange(location.pathname);
    }
  }, [location]);
  return null;
}

export default RouterListener;
