import React, { Component } from 'react';
import { toast } from 'react-toastify';
import FeaturedDrop from '../components/featuredDrop/featuredDrop';
import CreatorManager from '../managers/Creator';
import FAQ from '../components/about/faq';
import SkullJewerlyFAQ from '../components/about/skulljewerlyFaq';

class Drop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropData: null,
    };
  }

  getDropData = async (dropId) => {
    const dropData = await CreatorManager.getSingleDropV2(dropId);
    if (dropData) {
      this.setState({
        dropData,
      });
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    if (window.location.hash.includes('register=success')) {
      toast.success('Successfully created your account.');
      window.location.hash = '';
    }
    if (window.location.hash.includes('login=success')) {
      toast.success('Log in success.');
      window.location.hash = '';
    }
    // get the parameter from the URL because react router is stupid and now forces you to use hooks.
    const urlPatternArray = window.location.href.split('/');
    let dropId = urlPatternArray[urlPatternArray.length - 1];
    if (dropId.includes('#')) {
      dropId = dropId.substring(0, dropId.length - 1);
    }
    await this.getDropData(dropId);
  }

  render() {
    const { dropData } = this.state;
    return (
      <div>
        {dropData
        && (
        <div className="drop-details-page">
          <FeaturedDrop
            assetType="video"
            pageType="details"
            data={dropData} />
          {process.env.CREATOR_SUBDOMAIN === 'skulljewelry'
            ? <SkullJewerlyFAQ />
            : <FAQ />}
        </div>
        )}
      </div>
    );
  }
}

export default Drop;
