import React, { Component } from 'react';

class CardOnFile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { cardDetails } = this.props;
    return (
      <div>
        current card on file:
        {cardDetails
          ? (
            <div className="card-container">
              <div className="card-brand">
                  &nbsp;
                {cardDetails.brand}
              </div>
              <div className="card-number">
                **** **** ****
                {' '}
                <span>{cardDetails.last4}</span>
              </div>
              <div className="card-expiry">
                Expires:&nbsp;
                {cardDetails.expMonth}
                {' '}
                /
                {' '}
                {cardDetails.expYear}
              </div>
            </div>
          )
          : <div>Really? No card?</div>}
        
      </div>
    );
  }
}

CardOnFile.propTypes = {
  cardDetails: PropTypes.string,
};

export default CardOnFile;
