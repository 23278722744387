import React, { useState, useEffect } from 'react';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
// import IconButton from '@mui/material/IconButton';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
// import {NftCard} from '../nft/NftCard';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import TransferNFT from './transferNFT';
import TransferWallet from './transferWallet';
import TransferToggle from './transferToggle';
import AccountManager from '../../managers/Account';
import Throbber from '../throbber';
import NftCard from '../nft/NftCard';
import SimpleModal from '../modal/modal';
import Gallery from './gallery';

function AccountCollection() {
  const [isLoading, setIsLoading] = useState(true);
  const [isDetailsPage, setDetailsPage] = useState(false);
  const [collection, setCollection] = useState([]);
  const [transfer, setUserTransferModal] = useState(false);
  const [walletTransfer, setWalletTransferModal] = useState(false);
  const [showPassModal, setShowPassModal] = useState(false);
  const [isPassGenerating, setIsPassGenerating] = useState(true);
  const [selectedNft, setSelectedNft] = useState(null);
  const [nftPass, setNftPass] = useState(null);
  const [transferSelector, toggleTransferSelectorModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isModalLoading, setModalLoadingState] = useState(false);
  const [showFileViewer, setFileViewerState] = useState(false);
  const [updatedNft, setUpdatedNft] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  // const [tokenSupply, setTokenSupply] = useState(0);

  const { nftId } = useParams();

  const location = useLocation();
  
  const closeFileViewer = () => {
    setFileViewerState(false);
    setSelectedFile(null);
  };

  const toggleTransferModal = (showModal, nft) => {
    toggleTransferSelectorModal(showModal);
    setUpdatedNft(nft);
  };

  const setTransferState = (nft) => {
    // setUserTransferModal(nft);
    toggleTransferModal(true, nft);
    setUpdatedNft(nft);
  };

  const showUserTransferModal = () => {
    toggleTransferSelectorModal(false);
    setUserTransferModal(updatedNft);
  };

  const showWalletTransferModal = () => {
    toggleTransferSelectorModal(false);
    setWalletTransferModal(updatedNft);
  };

  const togglePassModal = async (nft) => {
    setShowPassModal(!showPassModal);
    setIsPassGenerating(true);
    console.log(nft.nftId);
    setSelectedNft(nft);
    const payload = {
      nftId: nft.nftId,
    };
    const applePass = await AccountManager.generateApplePass(AccountManager.getToken(), payload).then((response) => {
      // Create blob link to download
      console.log('=== generating apple pass ===');
      console.log(response);
      // const url = window.URL.createObjectURL(
      //   new Blob([response]),
      // );
      // setNftPass(url);
      if (response && response.success) {
        toast.success('Successfully created Apple pass.');
        setNftPass(response.passUri);
      } else {
        toast.error('Error creating Apple pass. Please try again later.');
        setShowPassModal(false);
      }
      setIsPassGenerating(false);
    });
    console.log({ applePass });
  };

  const downloadNftPass = () => {
    const link = document.createElement('a');
    link.href = nftPass;
    link.setAttribute('download', 'nftPass.pkpass');
    document.body.appendChild(link);
    console.log('=== download pass ===');
    console.log(nftPass);
    link.click();
  };

  const showViewer = (viewerData) => {
    setSelectedFile(viewerData);
    setFileViewerState(true);
  };

  const updateCollection = async () => {
    // updates the collection in memory
    console.log(updatedNft);
    // add the newItem to the collection and replace the old one
    console.log(collection);
  };

  const confirmCancel = async () => {
    updatedNft.hasPendingTransfer = false;
    setModalLoadingState(true);

    const payload = {
      nftId: updatedNft.nftId,
      creatorId: process.env.CREATOR_ID,
    };

    const cancelled = await AccountManager.cancelTransfer(AccountManager.getToken(), payload);

    if (cancelled && cancelled.success) {
      toast.success('Successfully cancelled transfer.');
    } else {
      toast.error('Oops. Something went wrong. Please try again later.');
    }
    setModalLoadingState(true);
    setShowModal(false);
    updateCollection();
  };

  const confirmTransfer = () => {
    updatedNft.hasPendingTransfer = true;
    updateCollection();
    setUserTransferModal(false);
  };

  const cancelTransfer = (newItem) => {
    setUpdatedNft(newItem);
    setShowModal(true);
  };

  const getCollection = async () => {
    // get the collection from the server
    setIsLoading(true);
    setUserTransferModal(false);
    AccountManager.getCollection(AccountManager.getToken()).then((res) => {
      setCollection(res);
      setHasError(false);
      setIsLoading(false);
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
      setHasError(true);
      setCollection([]);
      toast.error('Oops. There was a problem getting your collection data. Please try again later.');
    });
  };

  const closeWalletTransferModal = (doUpdate) => {
    setWalletTransferModal(false);
    if (doUpdate) {
      getCollection();
    }
  };

  useEffect(() => {
    if (nftId) {
      setDetailsPage(true);
      setIsLoading(false);
    }
    // else {
    //   getCollection();
    // }
  }, []);

  useEffect(() => {
    if (nftId) {
      setDetailsPage(true);
    } else {
      setDetailsPage(false);
      if (collection.length === 0) {
        getCollection();
      }
    }
  }, [location]);

  return (
    <div>
      {isDetailsPage
      && <Outlet />}
      
      {showFileViewer
        && (
        <div className="file-viewer" onClick={() => closeFileViewer()}>
          <div className="file-viewer__inner">
            <a className="modal__close -outside-right" href="#" onClick={() => closeFileViewer()}>
              <img src="https://ipfs.ethosnft.com/global/icon-close-dark.svg" alt="Close modal" />
            </a>

            {selectedFile && selectedFile.asset
            && (
              <Gallery creator={selectedFile.creator} />
            )}
          </div>
        </div>
        )}
      <SimpleModal isOpen={showModal} onBeforeClose={() => setShowModal(false)}>
        {isModalLoading
          && <Throbber throbberText="Cancelling transfer! Please stand by..." />}
        Are you sure you want to cancel this transfer?
        <div className="form-cta">
          <button
            className="button button-small button-confirm -no-margin"
            type="submit"
            onClick={(e) => confirmCancel(e)}>
            Confirm Cancel
          </button>
          &nbsp;
          <button
            className="button button-small -no-margin"
            type="submit"
            onClick={() => setShowModal(false)}>
            Back
          </button>
        </div>
      </SimpleModal>

      <SimpleModal isOpen={showPassModal} onBeforeClose={() => setShowPassModal(false)}>
        {showPassModal && (
          <div>
            {isPassGenerating
              && <Throbber throbberText="Generating Apple Pass! Please stand by..." />}
            <div className="transfer-success register-results">
              <img className="register-results__icon" src="https://ipfs.ethosnft.com/ethos/icon-success.svg" alt="" />
              <div className="register-results__header">Success!</div>
              <p>
                Apple pass for this NFT has been created. Click the Add to wallet button below to add this to your wallet.
              </p>
            </div>
            <div className="form-cta -align-center">
              <a href={nftPass} className="button button-apple-wallet">
                <img src="http://ipfs.ethosnft.com/ethos/button-apple-wallet.png" alt="Add to Apple Wallet" />
              </a>
              {/* &nbsp;&nbsp;
              <button
                className="btn"
                type="button"
                onClick={() => setShowPassModal(false)}>
                Close
              </button> */}
            </div>
          </div>
          
        // <div>
        //   <div className="modal-header">
        //     <div className="modal-title">
        //       <h2>Add to Mobile Wallet</h2>
        //     </div>
        //     <div className="modal-description">Add this NFT to your Apple wallet.</div>
        //   </div>
        //   <div className="modal-body">
        //     <button type="button" className="applewallet-button" label="apple-wallet" onClick={() => downloadNftPass()} download></button>
        //   </div>
        // </div>
        )}
      </SimpleModal>

      {transferSelector
      && (
      <div>
        <TransferToggle
          onTransferToUser={() => showUserTransferModal()}
          onTransferToWallet={() => showWalletTransferModal()}
          onClose={() => toggleTransferSelectorModal(false)} />
      </div>
      )}

      {walletTransfer
      && (
      <div>
        <TransferWallet
          nft={walletTransfer}
          onClose={(doUpdate) => closeWalletTransferModal(doUpdate)} />
      </div>
      )}

      {transfer
      && (
      <div>
        <TransferNFT nft={transfer} onClose={() => setUserTransferModal(false)} onSuccess={() => confirmTransfer()} />
      </div>
      )}

      {!isDetailsPage
      && (
      <div className="account-box -account-section -no-right-padding">
        {isLoading
        && <Throbber throbberText="Loading collection! Please stand by..." />}
        <h2 className="account-heading__h2">
          Account Collection
        </h2>
        <div className="account-subheading">
          View your digital assets here
        </div>

        {!hasError
          ? (
            <div className="collection-container__outer">
              { collection.length > 0 ? (
                collection.map((item) => (
                  <div key={item.dropId}>
                    <div className="collection-drop__header">
                      {item.name}
                    </div>
                    <div className="collection-container">
                      { item.nfts.length > 0 ? (
                        item.nfts.map((nft) => (
                          <NftCard
                            key={nft.nftId}
                            item={nft}
                            onViewerSelect={() => showViewer(nft)}
                            onTransfer={() => setTransferState(nft)}
                            onCancel={(cancelledTransfer) => cancelTransfer(cancelledTransfer)}
                            onGenerateNftPass={() => togglePassModal(nft)} />
                        ))
                      ) : (
                        <div>No digital assets for this collection.</div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-content -margin-right">
                  {'You haven\'t purchased any digital assets yet. Once you have purchased an NFT from the ethos multiverse, it will show up here.'}
                </div>
              )}
            </div>
          )
          : (
            <div className="collection-container__outer">
              <div className="no-content -margin-right">
                {'We are having trouble retrieving your collection. This is not your fault. We\'re working on restoring functionality ASAP. Please try again later.'}
              </div>
            </div>
          )}
      </div>
      )}
    </div>
  );
}
export default AccountCollection;
